@import "../../sass/variables";

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 4rem;
  background-color: $mainColorPrimary;
  align-items: center;
  z-index: 3;
  .navbar-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .logo-img {
      width: 10rem;
      height: 100%;
    }
    .options-container {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }
    .option {
      color: $basicColorSecondary;
      text-decoration: none;
      font-weight: bold;
      color: $textColorPrimary;
      font-size: 0.85rem;
      &:hover {
        border-bottom: 2px solid $mainColorSecondary;
        padding-bottom: 0.25rem;
        color: $mainColorSecondary;
      }
    }
    .pay-btn {
      border: 1px solid $btnColorPrimary;
      padding: 0.25rem 1rem;
      border-radius: 1rem;
      background-color: $btnColorPrimary;
      color: $basicColorPrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $basicColorPrimary;
        border-bottom: 1px solid $btnColorPrimary;
        color: $btnColorPrimary;
      }
    }
    .nb-mobile {
      display: none;
    }
  }
  .menu-options-container {
    position: absolute;
    top: 3rem;
    width: 100%;
    background-color: $basicColorPrimary;
    left: -40rem;
    .anchor-container {
      padding: 1rem 0 1rem 2rem;
      &:hover {
        color: $basicColorPrimary;
        background-color: $navbarMenuColorPrimary;
        .anchor {
          color: $basicColorPrimary;
        }
      }
    }
    .anchor {
      color: $basicColorSecondary;
      &:link {
        color: $basicColorSecondary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorSecondary;
      }
      &:active {
        color: $basicColorSecondary;
      }
    }
  }
  .open-menu {
    @keyframes openMenuNv {
      0% {
        left: -70rem;
      }
      100% {
        left: 0rem;
      }
    }
    animation-name: openMenuNv;
    animation-duration: 1s;
    left: 0rem;
  }
  .close-menu {
    @keyframes closeMenuNv {
      0% {
        left: 0rem;
      }
      100% {
        left: -70rem;
      }
    }
    animation-name: closeMenuNv;
    animation-duration: 1s;
    left: -70rem;
  }
  .ham-menu-icon {
    width: 1.5rem;
    height: 100%;
  }
}
.fixed-nb {
  position: fixed;
  top: 0;
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .navbar-container {
    .logo-img {
      width: 7rem !important;
      margin-left: 1rem;
    }
    .ham-menu-container {
      margin-right: 1rem;
    }
    .options-container {
      display: none !important;
    }
    .nb-mobile {
      display: unset !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .navbar-container {
    .logo-img {
      width: 7rem !important;
      margin-left: 1rem;
    }
    .ham-menu-container {
      margin-right: 1rem;
    }
    .options-container {
      display: none !important;
    }
    .nb-pay-btn-container {
      position: absolute;
      right: 15%;
      .nb-mobile {
        display: unset !important;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .navbar-container {
    .logo-container {
      margin-left: 10%;
    }
    .ham-menu-container {
      margin-right: 10% !important;
    }
    .ham-menu-container {
      margin-right: 1rem;
    }
    .options-container {
      display: none !important;
    }
    .nb-pay-btn-container {
      position: absolute;
      right: 18%;
      .nb-mobile {
        display: unset !important;
      }
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .navbar-subcontainer {
    margin: 0 1rem;
  }
  .menu-options-container {
    display: none;
  }
  .ham-menu-container {
    display: none;
  }
}

//Responsive Screen Small >
@media screen and (min-width: $screenSm) {
  .ham-menu-container {
    display: none;
  }
  .menu-options-container {
    display: none !important;
  }
}