@import "../../sass/variables";

.counter-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0rem 0;
  position: relative;
  background-color: $basicColorPrimary;
  padding-bottom: 1rem;
  .bg-skew {
    height: 7rem;
    width: 100%;
    background-color: $basicColorPrimary;
    position: absolute;
    z-index: 0;
  }
  .top {
    top: -6.5rem;
    clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
  }
  .bottom {
    top: 25rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 88%, 0 14%);
  }
  .counter-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;
    z-index: 1;
    .counter-title {
      font-weight: 500;
      color: $mainColorSecondary;
      font-size: 2rem;
    }
    .counter-info-container {
      display: flex;
      justify-content: center;
      align-items: baseline;
      column-gap: 5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .counter-section {
    .supercontainer {
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
    .counter-info-container {
      display: none !important;
    }
    .counter-info-container-mobile {
      height: 18rem;
    }
    .counter-info-container-tablet {
      display: none;
    }
    .bottom {
      top: 26rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .counter-section {
    .supercontainer {
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
    .counter-info-container {
      display: none !important;
    }
    .counter-info-container-tablet {
      display: none;
    }
    .counter-info-container-mobile {
      height: 18rem;
    }
    .bottom {
      top: 26rem;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .counter-section {
    .supercontainer {
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
    .counter-info-container {
      display: none !important;
    }
    .counter-info-container-mobile {
      display: none;
    }
    .bottom {
      top: 25rem;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .counter-section {
    .supercontainer {
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin: 0 1rem;
    }
    .counter-info-container-mobile {
      display: none;
    }
    .counter-info-container-tablet {
      display: none;
    }
  }
}

//Responsive Screen Small >
@media screen and (min-width: $screenSm) {
  .counter-section {
    .bg-skew {
      height: 8rem;
    }
    .top {
      top: -7.5rem;
    }
    .supercontainer {
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
    .counter-info-container-mobile {
      display: none;
    }
    .counter-info-container-tablet {
      display: none;
    }
  }
}
