@import "../../sass/variables";

.counter-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  min-height: 14rem;
  .img-counter {
    width: 7rem;
    height: 100%;
  }
  .nums-container {
    display: flex;
    color: $mainColorSecondary;
    .counter-txt {
        font-weight: 500;
        font-size: 2.8rem;
    }
  }
  .counter-subtitle {
    color: $basicColorPrimary;
    font-size: 1.1rem;
    text-align: center;
  }
  .counter-subtitle {
    color: $mainColorSecondary;
    font-size: 0.8rem;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .nums-container {
    font-size: 1rem !important;
  }
  .counter-subtitle {
    font-size: 0.5rem !important;
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .nums-container {
    font-size: 1.1rem !important;
  }
  .counter-subtitle {
    font-size: 0.6rem !important;
  }
}
