@import '../../sass/variables';

.not-found-page-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .not-found-page-subcontainer {
        width: 1024px;
        display: flex;
        align-items: center;
        padding-top: 10%;
    }
    .info-err-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 2rem;
        h1 {
            font-weight: 200;
            font-size: 2.1rem;
            margin-left: 0.5rem;
        }
        p {
            width: 80%;
            margin-top: 1rem;
            margin-left: 0.5rem;
        }
        .logo-otacc {
            width: 15rem;
        }
    }
}

//Responsive Mobile Small - Mobile Standard
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
    .not-found-page-section {
        .not-found-page-subcontainer {
            flex-direction: column;
            padding: 10% 0;
            margin: 15% 0;
        }
        .info-err-container {
            align-items: center;
            margin: 0 10%;
        }
        .img-err-container {
            margin-top: 3rem;
            width: 90%;
            .img-err {
                width: 100%;
            }
        }
    }
}

//Responsive Tablet Small - Tablet Standard
@media screen and (min-width: $tabletSm) and (max-width: calc($screenSm - 1px)) {
    .not-found-section {
        .info-err-container {
            margin-left:  10%;
        }
        .img-err-container {
            margin-right:  10%;
        }
    }
}