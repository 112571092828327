@import "../../sass/variables";

.job-offer-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  background-color: $basicColorPrimary;
  padding: 5rem 0;
  position: relative;
  .bg-skew {
    height: 7rem;
    width: 100%;
    background-color: $basicColorPrimary;
    position: absolute;
    z-index: 0;
  }
  .top {
    top: -6.5rem;
    clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
  }
  .bottom {
    height: 25rem;
    top: 32rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 69%, 0 48%);
  }
  .job-offer-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    .jo-title {
      color: $mainColorSecondary;
      font-weight: 500;
      font-size: 1.8rem;
    }
    .job-offer-info-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      justify-content: center;
      column-gap: 3rem;
    }
    .jo-video-container {
      position: relative;
    }
    .deco-video {
      position: absolute;
      width: 7rem;
      top: -5rem;
      left: -3rem;
    }
    .jo-info-video {
      width: 100%;
    }
    .jo-content-title {
      color: $mainColorSecondary;
      font-style: italic;
      font-size: 1.3rem;
    }
    .jo-text-content {
      margin-top: 2rem;
      color: $textColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .job-offer-section {
    padding: 2rem 0;
    .job-offer-subcontainer {
      margin: 0 1rem;
      row-gap: 6rem;
      .job-offer-info-container {
        display: grid;
        row-gap: 2rem;
        grid-template-columns: 1fr;
      }
      .jo-content-container {
        z-index: 2;
      }
      .jo-title {
        font-size: 1.5rem;
      }
    }
    .top {
      top: -6.5rem;
    }
    .bottom {
      top: 35rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .job-offer-section {
    padding: 2rem 0;
    .job-offer-subcontainer {
      margin: 0 10%;
      row-gap: 6rem;
      .job-offer-info-container {
        display: grid;
        row-gap: 2rem;
        grid-template-columns: 1fr;
      }
      .jo-content-container {
        z-index: 2;
      }
      .jo-title {
        font-size: 1.5rem;
      }
    }
    .top {
      top: -6rem;
    }
    .bottom {
      top: 38rem;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .job-offer-section {
    .job-offer-subcontainer {
      margin: 0 10%;
      row-gap: 6rem;
      .jo-content-container {
        z-index: 2;
      }
      .jo-title {
        font-size: 1.5rem;
      }
    }
    .top {
      top: -6rem;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .job-offer-subcontainer {
    margin: 0 1rem;
  }
}
