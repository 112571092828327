@import "../../sass/variables";

.payment-methods-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding: 5rem 0;
  .payment-methods-subcontainer {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    .pm-info-title {
      color: $mainColorSecondary;
      font-weight: 500;
      font-size: 2rem;
    }
    .pm-info-content {
      color: $textColorPrimary;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
    .pm-options-container {
      display: flex;
      align-items: center;
    }
    .option-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .pm-btn-container {
      width: 70%;
      display: flex;
      justify-content: center;
    }
    .pm-opt-img {
      width: 20rem;
      height: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .payment-methods-section {
    padding: 0 !important;
    margin: 7rem 0 !important;
    .payment-methods-subcontainer {
      display: grid;
      .pm-info-container {
        margin: 0 1rem;
      }
      .pm-options-container {
        display: grid;
        grid-template-rows: 1fr 1fr;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .payment-methods-section {
    padding: 0 !important;
    margin: 7rem 0 !important;
    .payment-methods-subcontainer {
      display: grid;
      .pm-info-container {
        margin: 0 10%;
      }
      .pm-options-container {
        display: grid;
        grid-template-rows: 1fr 1fr;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .payment-methods-section {
    .payment-methods-subcontainer {
      display: grid;
      grid-template-rows: 1fr 1fr;
      .pm-info-container {
        margin: 0 10%;
      }
      .pm-options-container {
        justify-content: space-around;
      }
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm)) {
  .payment-methods-subcontainer {
    margin: 0 1rem;
  }
}
