@import "../../sass/variables";

.contact-navbar-section {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  .contact-navbar-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    .contact-container {
      display: flex;
      align-items: center;
      column-gap: 0.2rem;
      color: $textColorPrimary;
      font-size: 0.8rem;
    }
    .btn-cn {
      color: $textColorPrimary;
      text-decoration: none;
      &:hover {
        border-bottom: 2px solid $mainColorSecondary;
        color: $mainColorSecondary;
      }
    }
    .contact-icon {
      width: 1rem;
      height: 1rem;
    }
    .social-media-container {
      display: flex;
      column-gap: 1rem;
    }
    .social-media-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .contact-navbar-section {
    padding-right: 0rem;
    .contact-navbar-subcontainer {
      column-gap: 0;
    }
    .contacts-container-mobile {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      color: $textColorPrimary;
      width: 100%;
      justify-content: center;
      .contact-subcontainer-mobile {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        column-gap: 0.5rem;
      }
    }
    .contact-container {
      display: none !important;
    }
    .social-media-container {
      display: none !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .contact-navbar-section {
    padding-right: 0rem;
    .contact-navbar-subcontainer {
      column-gap: 0;
    }
    .contacts-container-mobile {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      color: $textColorPrimary;
      width: 100%;
      justify-content: center;
      .contact-subcontainer-mobile {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        column-gap: 0.5rem;
      }
    }
    .contact-container {
      display: none !important;
    }
    .social-media-container {
      display: none !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .contact-navbar-section {
    padding-right: 0rem;
    .contact-navbar-subcontainer {
      column-gap: 0;
    }
    .contacts-container-mobile {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      color: $textColorPrimary;
      width: 100%;
      justify-content: center;
      .contact-subcontainer-mobile {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
      }
    }
    .contact-container {
      display: none !important;
    }
    .social-media-container {
      display: none !important;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .contact-navbar-subcontainer {
    margin: 0 1rem;
  }
  .contacts-container-mobile {
    display: none;
  }
}

//Responsive Screen Small >
@media screen and (min-width: $screenSm) {
  .contacts-container-mobile {
    display: none;
  }
}
