@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #222222;
  padding: 5rem 0;
  position: relative;
}
.footer .bg-skew {
  height: 7rem;
  width: 100%;
  background-color: #222222;
  position: absolute;
  z-index: 0;
}
.footer .top {
  top: -6.8rem;
  -webkit-clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
          clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
  transform: rotateY(180deg);
}
.footer .footer-subcontainer {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.5rem;
}
.footer .footer-subcontainer .info-footer-container {
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 15% 20% 20% 15% 25%;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  color: #ffffff;
}
.footer .footer-subcontainer .info-content-title {
  font-size: 1.5rem;
  font-weight: 500;
}
.footer .footer-subcontainer .info-content-subcontainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 1rem;
}
.footer .footer-subcontainer .info-content-anchor {
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.footer .footer-subcontainer .info-content-anchor:link {
  color: #ffffff;
  text-decoration: none;
}
.footer .footer-subcontainer .info-content-anchor:visited {
  color: #ffffff;
}
.footer .footer-subcontainer .info-content-anchor:active {
  color: #ffffff;
}
.footer .footer-subcontainer .info-content-anchor:hover {
  color: #014da1;
}
.footer .footer-subcontainer .social-media-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.footer .footer-subcontainer .sm-icons-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.footer .footer-subcontainer .sm-title {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;
  color: #000000;
  background-color: #ffffff;
  font-weight: bold;
}
.footer .footer-subcontainer .social-media-icon {
  width: 2rem;
  height: 100%;
}
.footer .footer-subcontainer .pay-methods-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.footer .footer-subcontainer .chat-btn-footer {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 2rem;
  color: #000000;
  background-color: #ffffff;
  font-weight: bold;
}
.footer .footer-subcontainer .chat-btn-footer:link {
  color: #000000;
  text-decoration: none;
}
.footer .footer-subcontainer .chat-btn-footer:visited {
  color: #000000;
}
.footer .footer-subcontainer .chat-btn-footer:active {
  color: #000000;
}
.footer .footer-subcontainer .chat-btn-footer:hover {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #222222;
}
.footer .footer-subcontainer .custom-font-size {
  font-size: 1.2rem;
}
.footer .footer-subcontainer .pm-companies-container {
  display: flex;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  align-items: center;
}
.footer .footer-subcontainer .pm-cmp-logo {
  width: 8rem;
  height: 100%;
  max-height: 8rem;
}
.footer .footer-subcontainer .copy-container {
  color: #ffffff;
  font-size: 0.8rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .footer {
    padding: 0 0 5rem 0;
  }
  .footer .info-footer-container {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr !important;
    justify-content: center;
  }
  .footer .info-footer-container .deco-img-footer {
    justify-self: center;
  }
  .footer .info-footer-container .social-media-container {
    align-items: center;
  }
  .footer .info-footer-container .sm-title {
    font-size: 0.9rem;
  }
  .footer .chat-btn-footer {
    font-size: 0.8rem;
  }
  .footer .pm-companies-container {
    display: none !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .footer {
    padding: 0 0 5rem 0;
  }
  .footer .footer-subcontainer {
    margin: 0 10%;
  }
  .footer .info-footer-container {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr !important;
    justify-content: center;
  }
  .footer .info-footer-container .deco-img-footer {
    justify-self: center;
  }
  .footer .info-footer-container .social-media-container {
    align-items: center;
  }
  .footer .info-footer-container .sm-title {
    font-size: 0.9rem;
  }
  .footer .chat-btn-footer {
    font-size: 0.8rem;
  }
  .footer .pm-companies-container {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footer {
    padding: 0 0 5rem 0;
  }
  .footer .footer-subcontainer {
    margin: 0 10%;
  }
  .footer .info-footer-container {
    width: 80% !important;
  }
  .footer .info-footer-container .deco-img-footer {
    justify-self: center;
  }
  .footer .info-footer-container .social-media-container {
    align-items: center;
  }
  .footer .info-footer-container .sm-icons-container {
    width: 100%;
  }
  .footer .info-footer-container .sm-title {
    font-size: 0.9rem;
  }
  .footer .chat-btn-footer {
    font-size: 0.8rem;
  }
  .footer .pm-cmp-logo {
    width: 4rem !important;
  }
}/*# sourceMappingURL=Footer.css.map */