@import "../../sass/variables";

.salesforce-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding-top: 5rem;
  padding-bottom: 10rem;
  .salesforce-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;
    .salesforce-title {
      color: $mainColorSecondary;
      font-weight: 500;
      width: 70%;
      font-size: 2rem;
      text-align: center;
    }
    .sf-info-container {
      display: flex;
      align-items: center;
      column-gap: 2rem;
    }
    .sf-video-container {
      position: relative;
    }
    .deco-img {
      position: absolute;
      z-index: -1;
      width: 7rem;
      top: -5rem;
      left: -3rem;
    }
    .sf-video {
      width: 100%;
    }
    .sf-anchor {
      display: flex;
      justify-content: center;
    }
    .salesforce-logo {
      width: 60%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .salesforce-section {
    .salesforce-subcontainer {
      margin: 0 1rem;
      .salesforce-title {
        font-size: 1.5rem;
      }
      .sf-info-container {
        flex-direction: column;
        row-gap: 5rem;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .salesforce-section {
    .salesforce-subcontainer {
      margin: 0 10%;
      .salesforce-title {
        font-size: 1.5rem;
      }
      .sf-info-container {
        flex-direction: column;
        row-gap: 5rem;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .salesforce-section {
    .salesforce-subcontainer {
      margin: 0 10%;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .salesforce-subcontainer {
    margin: 0 1rem;
  }
}