@import "../../sass/variables";

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $mainColorFourth;
  padding: 5rem 0;
  position: relative;
  .bg-skew {
    height: 7rem;
    width: 100%;
    background-color: $mainColorFourth;
    position: absolute;
    z-index: 0;
  }
  .top {
    top: -6.8rem;
    clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
    transform: rotateY(180deg);
  }
  .footer-subcontainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.5rem;
    .info-footer-container {
      width: 100%;
      display: grid;
      align-items: flex-start;
      grid-template-columns: 15% 20% 20% 15% 25%;
      column-gap: 2rem;
      color: $basicColorPrimary;
    }
    .info-content-title {
      font-size: 1.5rem;
      font-weight: 500;
    }
    .info-content-subcontainer {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      margin-top: 1rem;
    }
    .info-content-anchor {
      display: flex;
      flex-direction: column;
      color: $basicColorPrimary;
      &:link {
        color: $basicColorPrimary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorPrimary;
      }
      &:active {
        color: $basicColorPrimary;
      }
      &:hover {
        color: $mainColorSecondary;
      }
    }
    .social-media-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .sm-icons-container {
      width: 90%;
      display: flex;
      justify-content: space-between;
    }
    .sm-title {
      border: 1px solid $basicColorPrimary;
      border-radius: 1rem;
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 0;
      color: $basicColorSecondary;
      background-color: $basicColorPrimary;
      font-weight: bold;
    }
    .social-media-icon {
      width: 2rem;
      height: 100%;
    }
    .pay-methods-container {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
    .chat-btn-footer {
      border: 1px solid $basicColorPrimary;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 2rem;
      color: $basicColorSecondary;
      background-color: $basicColorPrimary;
      font-weight: bold;
      &:link {
        color: $basicColorSecondary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorSecondary;
      }
      &:active {
        color: $basicColorSecondary;
      }
      &:hover {
        color: $basicColorPrimary;
        border: 1px solid $basicColorPrimary;
        background-color: $mainColorFourth;
      }
    }
    .custom-font-size {
      font-size: 1.2rem;
    }
    .pm-companies-container {
      display: flex;
      column-gap: 2rem;
      align-items: center;
    }
    .pm-cmp-logo {
      width: 8rem;
      height: 100%;
      max-height: 8rem;
    }
    .copy-container {
      color: $basicColorPrimary;
      font-size: 0.8rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .footer {
    padding: 0 0 5rem 0;
    .info-footer-container {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr !important;
      justify-content: center;
      .deco-img-footer {
        justify-self: center;
      }
      .social-media-container {
        align-items: center;
      }
      .sm-title {
        font-size: 0.9rem;
      }
    }
    .chat-btn-footer {
      font-size: 0.8rem;
    }
    .pm-companies-container {
      display: none !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .footer {
    padding: 0 0 5rem 0;
    .footer-subcontainer {
      margin: 0 10%;
    }
    .info-footer-container {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr !important;
      justify-content: center;
      .deco-img-footer {
        justify-self: center;
      }
      .social-media-container {
        align-items: center;
      }
      .sm-title {
        font-size: 0.9rem;
      }
    }
    .chat-btn-footer {
      font-size: 0.8rem;
    }
    .pm-companies-container {
      display: none !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .footer {
    padding: 0 0 5rem 0;
    .footer-subcontainer {
      margin: 0 10%;
    }
    .info-footer-container {
      width: 80% !important;
      .deco-img-footer {
        justify-self: center;
      }
      .social-media-container {
        align-items: center;
      }
      .sm-icons-container {
        width: 100%;
      }
      .sm-title {
        font-size: 0.9rem;
      }
    }
    .chat-btn-footer {
      font-size: 0.8rem;
    }
    .pm-cmp-logo {
      width: 4rem !important;
    }
  }
}
