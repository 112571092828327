@import "../../sass/variables";

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $basicColorPrimary;
  border: 1px solid $mainColorSecondary;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
  .header-title-container {
    background-color: $mainColorSecondary;
    border: 1px solid $mainColorSecondary;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: $basicColorPrimary;
    padding: 1rem 3rem;
    text-align: center;
    font-size: 0.9rem;
  }
  .form-element {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin: 3rem 2rem;
    .grid-inputs-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 1.5rem;
    }
    input {
      margin-top: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      border-radius: 0.5rem !important;
      border: 2px solid $mainColorPrimary !important;
      width: 92%;
      font-family: "Roboto", sans-serif;
    }
    .input-comment {
      margin-top: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      border-radius: 0.5rem !important;
      border: 2px solid $mainColorPrimary !important;
      width: 96%;
      font-family: "Roboto", sans-serif;
      height: 6rem;
      resize: none;
    }
    select {
      margin-top: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      border-radius: 0.5rem;
      border: 2px solid $mainColorPrimary !important;
      width: 100%;
      background-color: $mainColorPrimary;
      font-family: "Roboto", sans-serif;
    }
    .mandatory-text {
      font-size: 0.8rem;
      color: $errorMsgColor;
      // font-family: $sarabunLightFont;
    }
    .hide {
      opacity: 0;
    }
    .advise-label {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      font-size: 0.85rem;
    }
    .error {
      color: $errorMsgColor;
    }
    .checkbox-input {
      width: 2rem;
    }
    .submit-btn {
      width: 100%;
      height: 3rem;
      color: $mainColorSecondary;
      background-color: $btnColorSecondary;
      border: 1px solid $btnColorSecondary;
      border-radius: 0.5rem;
      cursor: pointer;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Roboto", sans-serif;
      &:hover {
        background-color: $mainColorSecondary;
        border: 1px solid $mainColorSecondary;
        color: $mainColorPrimary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .grid-inputs-container {
    grid-template-columns: none !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .grid-inputs-container {
    grid-template-columns: none !important;
  }
}
