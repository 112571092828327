@import "../../sass/variables";

.digital-sign-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  background-color: $mainColorThird;
  .digital-sign-subcontainer {
    width: 1024px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 2rem;
    .ds-info-container {
      color: $basicColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      align-self: flex-end;
      margin-bottom: 7rem;
    }
    .ds-info-title {
      font-weight: 500;
    }
    .man-img {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .ds-info-btns-container {
      display: flex;
      justify-content: space-between;
      width: 90%;
      column-gap: 2rem;
    }
    .btn-container {
      width: 35%;
    }
  }
  .digital-sign-btn-subcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 1.5rem 0;
    background-color: $mainColorSecondary;
    .dsb-title {
      color: $basicColorPrimary;
      font-weight: 500;
    }
    .ds-btn-subsection {
      width: 3rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .digital-sign-section {
    margin-top: 0;
    padding-top: 50%;
    .digital-sign-subcontainer {
      width: 100%;
      display: unset;
      .ds-info-container {
        margin: 0 1rem 7rem 1rem;
        align-items: center;
      }
      .ds-info-btns-container {
        flex-direction: column;
        row-gap: 1.5rem;
      }
      .btn-container {
        width: 75%;
      }
    }
    .man-img {
      display: none;
    }
    .digital-sign-btn-subcontainer {
      flex-direction: column;
      row-gap: 1rem;
    }
    .dsb-title {
      margin: 0 1rem;
    }
    .ds-btn-subsection {
      display: flex;
      justify-content: center;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .digital-sign-section {
    margin-top: 0;
    padding-top: 45%;
    .digital-sign-subcontainer {
      width: 100%;
      display: unset;
      .ds-info-container {
        margin: 0 10% 7rem 10%;
        align-items: center;
      }
      .ds-info-btns-container {
        flex-direction: column;
        row-gap: 1.5rem;
      }
      .btn-container {
        width: 75%;
      }
    }
    .man-img {
      display: none;
    }
    .digital-sign-btn-subcontainer {
      flex-direction: column;
      row-gap: 1rem;
    }
    .dsb-title {
      margin: 0 1rem;
    }
    .ds-btn-subsection {
      display: flex;
      justify-content: center;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .digital-sign-section {
    margin-top: 0;
    padding-top: 15%;
    .digital-sign-subcontainer {
      width: 100%;
      .ds-info-container {
        margin: 0 0 0 10%;
        align-self: center;
      }
      .ds-info-btns-container {
        flex-direction: column;
        row-gap: 1.5rem;
      }
      .btn-container {
        width: 75%;
      }
    }
    .dsb-title {
      margin: 0 1rem;
    }
    .ds-btn-subsection {
      display: flex;
      justify-content: center;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .digital-sign-subcontainer {
    margin-left: 5%;
  }
}
